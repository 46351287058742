import { ISbResult } from 'storyblok-js-client'
import type { Ref } from 'vue'

export const useAsyncStoryblokQuery = async (
  key: string,
  slug: string,
  params = {}
) => {
  const runtimeConfig = useRuntimeConfig()
  params = {
    version: runtimeConfig.public.STORYBLOK.VERSION,
    ...params,
  }

  const uniqueKey = `${JSON.stringify(params)}`
  const resultState: Ref<ISbResult> = useState(
    `${uniqueKey}-${key}`,
    () => ({} as ISbResult)
  )
  const storyblokApiInstance = useStoryblokApi()

  const { data } = await useAsyncData(
    `${uniqueKey}-asyncdata`,
    async () => await storyblokApiInstance.get(slug, params)
  )
  if (data.value) resultState.value = data.value

  return resultState
}
